













import { computed, defineComponent } from '@vue/composition-api';
import { IATFAttrs } from './types';

export default defineComponent({
  name: 'AboveTheFoldView',
  inheritAttrs: false,
  setup(_, context) {
    const {
      heroHeightPercentage,
      contentContainer: cmsArray,
    } = (context?.attrs ?? {}) as IATFAttrs;

    const getHeroPercentages = (index: number) => {
      const firstContentPercentage = heroHeightPercentage || 100;
      const secondContentPercentage = 100 - firstContentPercentage;

      return index ? `${secondContentPercentage}%` : `${firstContentPercentage}%`;
    };

    return {
      findAboveTheFoldViewContent: computed(() => (cmsArray ?? [])
        .map((component: any, index: number) => ({
          ...component,
          styles: {
            height: getHeroPercentages(index),
          },
        }))),
    };
  },
});
